<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <active-events></active-events>
      </b-col>
      <!-- <b-col xl="8" md="6">
        <b-card>
        </b-card>
      </b-col> -->
    </b-row>

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">

          <b-col lg="6" md="3" cols="6">
            <b-card>
            </b-card>
          </b-col>

          <b-col lg="6" md="3" cols="6">
            <b-card>
            </b-card>
          </b-col>
          <b-col lg="12" md="6">
            <b-card>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <b-card>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="8">
        <b-card>
        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card>
        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card>
        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card>
        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card>
        </b-card>
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import ActiveEvents from './ActiveEvents.vue'

export default {
  components: {
    BCol, BRow, BCard, 
    ActiveEvents
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
