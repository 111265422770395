<template>
    <b-card body-class="pb-50">
        <h3>Active Events</h3>
        <h4 class="font-weight-bolder mb-1 text-success">
            {{ activeEvents }}
            <feather-icon icon="TrendingUpIcon" size="24" />
        </h4>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import axios from "@/libs/axios";
import { decryptString } from '@/libs/crypto';

export default {
    components: {
        BCard,
    },
    data() {
        return {
            activeEvents: null,
        }
    },
    async created() {
        await axios
            .get("/event?mxs_event_Active=1&Aforos=" + decryptString(localStorage.getItem('Aforo_permit')))
            .then((results) => {
                this.activeEvents = results.data.length;
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
    },
    mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
}
</script>